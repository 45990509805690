<template>
  <section class="main">
    <div class="row">
      <div class="col-lg-3 col-xl-3">
        <div class="card">
          <div class="card-body text-center">
            <img src="@/assets/images/user.png" alt="profile-image" class="rounded-circle avatar-xl img-thumbnail">
            <h4 class="mt-3 mb-0">{{ this.user.full_name }}</h4>
            <div class="mt-3">
              <h5 class="text-lg-start">Информация</h5>
              <div class="user-info">
                <span class="info-title">ФИО: </span>
                <span class="info-text">{{ this.user.fullname ? this.user.fullname : '-'}}</span>
              </div>
              <div class="user-info ">
                <span class="info-title">Телефон: </span>
                <span class="info-text">{{ this.user.phone }}</span>
              </div>
              <div class="user-info">
                <span class="info-title">Email: </span>
                <span class="info-text">{{ this.user.email ? this.user.email :'-' }}</span>
              </div>
              <div class="user-info">
                <span class="info-title">Внешний код: </span>
                <span class="info-text">{{ this.user.ext ? this.user.ext :'-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4">
        <div class="card">
          <div class="card-header">
            Изменить данные
          </div>
          <div class="card-body">
            <div class="form-group mb-1">
              <label class="col-form-label">ФИО</label>
              <input type="text" class="form-control" v-model="user.fullname" placeholder="ФИО">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Email</label>
              <input type="text" class="form-control" v-model="user.email" placeholder="Email">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Внутренний номер</label>
              <input type="text" class="form-control" v-model="user.ext" placeholder="Внутренний номер">
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <button type="button" class="btn btn-primary" @click="saveChanges">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'profile',
  data: function () {
    return {
      user: {
        id: 0,
        fullname: '',
        phone: '',
        email: '',
        ext: '',
      }
    }
  },
  computed: {
    ...mapGetters(['profileGet']),
  },
  async mounted() {
    this.getProfile();
  },
  methods: {
    getProfile: function(){
      this.$store.dispatch('getProfile', this.$store.getters.user_id).then(() => {
        this.user.id = this.profileGet.id;
        this.user.fullname = this.profileGet.full_name;
        this.user.email = this.profileGet.email;
        this.user.phone = this.profileGet.phone;
        this.user.ext = this.profileGet.ext;
      });
    },
    saveChanges: function(){
      this.$store.dispatch('saveProfile', this.user).then(() => {
        this.getProfile();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-xl {
  height: 6rem;
  width: 6rem;
}
</style>